import { render, staticRenderFns } from "./add-car-category.popup.vue?vue&type=template&id=005d3542&scoped=true&"
import script from "./add-car-category.popup.vue?vue&type=script&lang=ts&"
export * from "./add-car-category.popup.vue?vue&type=script&lang=ts&"
import style0 from "./add-car-category.popup.vue?vue&type=style&index=0&id=005d3542&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005d3542",
  null
  
)

export default component.exports